import { SEO, WizardLayout } from 'components';

import React from 'react';

const NotFoundPage = () => (
  <WizardLayout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </WizardLayout>
);

export default NotFoundPage;
